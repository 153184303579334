import type { PostColormapApiResponse } from '@api/colormap/dist/enhancedColormapClient';
import { NetworkRequestStatuses } from '@local/webviz/dist/types';
import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';

import type { RootState } from '../store';

const getColormapCollection = 'getColormapCollection';
const getAssociationCollection = 'getAssociationCollection';

const colormapState = (state: RootState) => state.colormap;

export function getColormapLoadingStatusById(objectId: string) {
    return createSelector(colormapState, (stateRoot) => {
        let colormapLoadingStatus = NetworkRequestStatuses.UNINITIALIZED;
        let colormapStatus;
        let associationStatus;

        Object.values(stateRoot.queries).forEach((value) => {
            if (value && typeof value === 'object' && 'endpointName' in value) {
                if (value.endpointName === getColormapCollection) {
                    colormapStatus = value.status;
                } else if (
                    value.endpointName === getAssociationCollection &&
                    value.originalArgs &&
                    (value.originalArgs as { objectId: string }).objectId === objectId
                ) {
                    associationStatus = value.status;
                }
            }
        });

        if (colormapStatus === QueryStatus.rejected || associationStatus === QueryStatus.rejected) {
            colormapLoadingStatus = NetworkRequestStatuses.REJECTED;
        } else if (
            colormapStatus === QueryStatus.pending ||
            associationStatus === QueryStatus.pending
        ) {
            colormapLoadingStatus = NetworkRequestStatuses.PENDING;
        } else if (
            colormapStatus === QueryStatus.fulfilled &&
            associationStatus === QueryStatus.fulfilled
        ) {
            colormapLoadingStatus = NetworkRequestStatuses.FULFILLED;
        }

        return colormapLoadingStatus;
    });
}

export function getColormapById(colormapId: string) {
    return createSelector(colormapState, (stateRoot) => {
        let colormap: PostColormapApiResponse | undefined;
        Object.values(stateRoot.queries).forEach((value) => {
            if (value && 'endpointName' in value) {
                if (value.endpointName === getColormapCollection) {
                    const data = value.data as { colormaps: PostColormapApiResponse[] };
                    colormap = data.colormaps.find(
                        (colormapResponse) => colormapResponse.id === colormapId,
                    );
                }
            }
        });
        return colormap;
    });
}
