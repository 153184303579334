import { ErrorSvg } from '@local/svgs/dist/svg/Error';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import type { WorkspaceRoleOptionalResponse } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { useUpdateMlEnablementAdminMutation } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useParams, Link } from 'react-router-dom';

import {
    ERROR_PAGE_BUTTON,
    ERROR_PAGE_DESCRIPTION_TEXT_1,
    ERROR_PAGE_DESCRIPTION_TEXT_2,
    ERROR_PAGE_SUBTITLE,
    ERROR_PAGE_TITLE,
    NOTIFICATION_MESSAGE_1,
    NOTIFICATION_MESSAGE_2,
    FAIL_ML_STATE_CHANGE_MESSAGE,
    TABLE_HEADER_COL_1,
    TABLE_HEADER_COL_2,
    TABLE_HEADER_COL_3,
    ML_EMPTY_STATE_PAGE_TEXT,
    ML_EMPTY_STATE_PAGE_CTA,
} from 'src/strings';

export function WorkspaceListTable({ orgMlEnable }: { orgMlEnable?: boolean }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [messageInfo, setMessageInfo] = useState<string | undefined>(undefined);
    const [messageSeverity, setMessageSeverity] = useState<'success' | 'error'>('success');

    const params = useParams();

    const [updateMLFlag] = useUpdateMlEnablementAdminMutation();

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        pageNumber: number,
    ) => {
        setPage(pageNumber);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const { data, isFetching, isError } = fetchWorkspaces({
        isAdmin: true,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        sort: 'name',
    });

    const workspaces = data?.results ?? [];

    useEffect(() => {
        if (!isFetching && workspaces.length === 0) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
    }, [isFetching, workspaces]);

    const handleNotificationClose = () => {
        setOpen(false);
    };

    const handleSwitchChange = async (
        event: ChangeEvent<HTMLInputElement>,
        workspaceId: string,
    ) => {
        if (orgMlEnable) {
            const mlEnabled = event.target.checked;
            const message = mlEnabled ? NOTIFICATION_MESSAGE_1 : NOTIFICATION_MESSAGE_2;
            const result = await updateMLFlag({
                hubUrl: getHubUrlForCurrentOrg(),
                orgId: getOrgUuidFromParams(params),
                mlEnablementRequest: {
                    ml_enablements: [{ workspace_id: workspaceId, ml_enabled: mlEnabled }],
                },
            });
            if (!result.data) {
                setMessageInfo(FAIL_ML_STATE_CHANGE_MESSAGE);
                setMessageSeverity('error');
            } else {
                setMessageInfo(message);
                setMessageSeverity('success');
            }
            setOpen(true);
        }
    };

    const tableContent = useMemo(
        () => (
            <Stack ml={4} mr={4}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={data?.links.total || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Show"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        /* To move the pagination to the left */
                        display: 'flex',
                        alignItems: 'left',
                        fontSize: '10px',
                        /* override MUI css style */
                        '& .MuiTablePagination-toolbar': {
                            paddingLeft: '16px',
                        },
                        '& .MuiTablePagination-displayedRows': {
                            fontSize: '10px',
                        },
                    }}
                    slotProps={{
                        select: {
                            IconComponent: ExpandMoreIcon,
                        },
                    }}
                />
                <TableContainer
                    sx={{
                        maxHeight: 'calc(100vh - 250px)',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                    }}
                >
                    <Table aria-label="pagination table for workspaces">
                        <TableHead>
                            <TableRow key="table-header">
                                <TableCell sx={{ minWidth: '300px', fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_1}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_2}
                                </TableCell>
                                <TableCell sx={{ fontWeight: '600' }}>
                                    {TABLE_HEADER_COL_3}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetching ? (
                                <TableSkeleton
                                    automation-id="workspace-table-skeleton"
                                    rows={rowsPerPage}
                                    columns={3}
                                />
                            ) : (
                                workspaces.map((row: WorkspaceRoleOptionalResponse) => (
                                    <TableRow key={row.name}>
                                        <TableCell>
                                            <Box>
                                                <Stack direction="row" spacing={2}>
                                                    <Switch
                                                        size="small"
                                                        color="primary"
                                                        checked={row.ml_enabled && orgMlEnable}
                                                        onChange={(event) =>
                                                            handleSwitchChange(event, row.id)
                                                        }
                                                        disabled={!orgMlEnable}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            padding: '4px',
                                                        }}
                                                    >
                                                        {row.name}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body2">
                                                    {row.created_by.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body2">
                                                    <FormattedDate
                                                        value={row.updated_at}
                                                        month="short"
                                                        year="numeric"
                                                        day="2-digit"
                                                    />
                                                    <Box
                                                        component="span"
                                                        sx={{ paddingLeft: '4px' }}
                                                    >
                                                        <FormattedDate
                                                            value={row.updated_at}
                                                            hour="numeric"
                                                            minute="numeric"
                                                            hour12={false}
                                                        />
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        ),
        [workspaces, page, rowsPerPage],
    );

    const notification = useMemo(
        () => (
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleNotificationClose}
                automation-id="ml-notification"
            >
                <Alert
                    severity={messageSeverity}
                    variant="standard"
                    onClose={handleNotificationClose}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon fontSize="inherit" />,
                        error: <ErrorOutlineIcon fontSize="inherit" sx={{ color: 'white' }} />,
                    }}
                    sx={{
                        backgroundColor: messageSeverity === 'error' ? 'red' : 'default',
                        color: messageSeverity === 'error' ? 'white' : 'default',
                    }}
                >
                    {messageInfo}
                </Alert>
            </Snackbar>
        ),
        [open],
    );

    if (isError) {
        return <ErrorMessage />;
    }

    if (isEmpty) {
        return <EmptyState orgMlEnable={orgMlEnable} />;
    }

    return (
        <Box>
            {tableContent}
            {notification}
        </Box>
    );
}

export function ErrorMessage() {
    return (
        <Box>
            <Stack alignItems="center" pl={4} pr={4} pt={3} pb={3}>
                <ErrorSvg />
            </Stack>
            <Stack alignItems="center">
                <Typography variant="h3" pb={1}>
                    {ERROR_PAGE_TITLE}
                </Typography>
                <Typography variant="h5" pb={2}>
                    {ERROR_PAGE_SUBTITLE}
                </Typography>
                <Typography variant="body1" pb={2} align="center">
                    {ERROR_PAGE_DESCRIPTION_TEXT_1}
                    <br />
                    {ERROR_PAGE_DESCRIPTION_TEXT_2}
                </Typography>
                <Button
                    onClick={() => window.location.reload()}
                    variant="outlined"
                    color="secondary"
                    size="small"
                >
                    {ERROR_PAGE_BUTTON}
                </Button>
            </Stack>
        </Box>
    );
}

function EmptyState({ orgMlEnable }: { orgMlEnable?: boolean }) {
    const theme = useTheme();
    return (
        <Stack alignItems="center">
            <Box maxWidth="828px" width="100%" pl={4} pr={4} pt={10} pb={6}>
                <NotFoundSvg />
            </Box>

            <Stack alignItems="center">
                <Typography variant="h5" pb="6px">
                    {ML_EMPTY_STATE_PAGE_TEXT}
                </Typography>
                <Typography variant="body1" pb="6px" align="center">
                    {orgMlEnable && (
                        <Link
                            to="../workspaces"
                            style={{ color: theme.palette.text.primary }}
                            automation-id="ml-empty-state-cta"
                        >
                            {ML_EMPTY_STATE_PAGE_CTA}
                        </Link>
                    )}
                </Typography>
            </Stack>
        </Stack>
    );
}
