import type { LegendMappingState, LegendState } from '@local/webviz/dist/components/Legend';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { MappingClass } from '@local/webviz/dist/xyz';

import { useAppSelector } from 'src/store/store';
import { loadedObjectsMap } from 'src/store/visualization/selectors';

import { getViewIdFromObjectId } from '../snapshots/generateSnapshot';

/* eslint-disable no-underscore-dangle */
export function useLegends() {
    const loadedObjects = useAppSelector(loadedObjectsMap);
    const { getEntityState } = useBaseXyz();

    const loadedObjectKeys = Object.keys(loadedObjects);
    return loadedObjectKeys.reduce(
        (acc: LegendState, key) => {
            const { treeId, schema, isLegendShown } = loadedObjects[key];
            const viewId = getViewIdFromObjectId(treeId, schema ?? '');
            const entity = getEntityState(viewId);

            if (!entity || !('color_data' in entity)) {
                return acc;
            }
            const colorDataState = getEntityState(entity.color_data);

            if (!colorDataState || !('array' in colorDataState) || !('mapping' in colorDataState)) {
                return acc;
            }
            const arrayState = getEntityState(colorDataState.array);
            const arrayType = arrayState?.__class__;
            const mappingState = getEntityState(colorDataState.mapping);

            // For holeId category data theres only title id with no colors.
            // Cannot display Legend for it
            if (
                mappingState?.__class__ === MappingClass.Category &&
                mappingState.categories.length !== 2
            ) {
                return acc;
            }
            if (mappingState && 'id' in mappingState) {
                // populate mapping to artifact map
                if (acc.mappingIdToArtifactIdMap[mappingState.id]) {
                    acc.mappingIdToArtifactIdMap[mappingState.id].push(treeId);
                } else {
                    acc.mappingIdToArtifactIdMap[mappingState.id] = [treeId];
                }

                // manage unique legends
                const existingLegendState = acc.uniqueLegends[mappingState.id];
                if (existingLegendState) {
                    // if any artifact.isLegendShown is true, enable the active mapping id Legend toggle
                    if (!existingLegendState.enabled && isLegendShown) {
                        acc.uniqueLegends[mappingState.id] = {
                            ...existingLegendState,
                            enabled: isLegendShown,
                        };
                    }
                    return acc;
                }
                acc.uniqueLegends[mappingState.id] = {
                    mappingId: mappingState.id,
                    enabled: isLegendShown,
                    state: mappingState,
                    artifactId: treeId,
                    viewId,
                    attributeId: entity.color_data,
                    arrayType,
                } as LegendMappingState;
            }
            return acc;
        },
        { uniqueLegends: {}, mappingIdToArtifactIdMap: {} },
    );
}
