import { ScreenSpaceEventHandler, ScreenSpaceEventType } from 'cesium';
import { useEffect } from 'react';

export function useResetActiveCluster(
    cesiumWidget: any,
    selectedCluster: any,
    setSelectedCluster: (cluster: any) => void,
    resetClusters: () => void,
    setActiveObject: (object: any) => void,
    setHoveredBillboards: (billboards: any[]) => void,
) {
    useEffect(() => {
        if (!cesiumWidget || selectedCluster === null) return;

        const handler = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        handler.setInputAction(() => {
            setSelectedCluster(null);
            resetClusters();
            setActiveObject('');
            setHoveredBillboards([]);
        }, ScreenSpaceEventType.LEFT_CLICK);
    }, [
        cesiumWidget,
        selectedCluster,
        setSelectedCluster,
        resetClusters,
        setActiveObject,
        setHoveredBillboards,
    ]);
}
