import { Primitive } from 'cesium';
import { useCallback } from 'react';

export function useRemoveRectangleGeometries(cesiumWidget: any) {
    const removeRectangleGeometries = useCallback(() => {
        if (!cesiumWidget) return;
        const { primitives } = cesiumWidget.scene;
        for (let i = primitives.length - 1; i >= 0; i -= 1) {
            const primitive = primitives.get(i);
            if (primitive instanceof Primitive) {
                primitives.remove(primitive);
            }
        }
    }, [cesiumWidget]);

    return removeRectangleGeometries;
}
