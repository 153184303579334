import { makeStyles } from 'tss-react/mui';

const styles = () =>
    ({
        overlayDialog: {
            zIndex: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
